







import Vue from "vue";
import BaseShippingCompanies from "./partials/BaseShippingCompanies.vue";

export default Vue.extend({
  components: { BaseShippingCompanies },
});
